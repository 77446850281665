<template>
  <div class="profile-card p-20">
    <h3 class="card-title">Activities</h3>
    <div v-if="Object.keys(myActions).length === 0" class="no-action">You has no activity yet.</div>
    <div class="action-container" :key="renderKey">
      <div class="action-item" v-for="(value, name, index) in myActions" :key="name">
        <template v-if="index < shownMonth">
          <div class="action-time">
            <span>{{ name }}</span>
          </div>
          <div class="action-content" v-for="(v, n) in value" :key="n">
            <div class="action-stats" @click="toggleShow(name, n)">
              <span>{{ v.message }}</span>
              <i class="fas fa-chevron-down" v-if="v.show"></i>
              <i class="fas fa-chevron-up" v-else></i>
            </div>
            <template v-if="v.show">
              <div class="action-message" v-for="action in v" :key="action.id">{{ action.message }}</div>
            </template>
            <!-- <div class="action-time">{{ action.formattedTime }}</div> -->
            <div class="action-type">
              <img :src="require(`@/assets/images/profile/${n}.png`)" alt="type" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <button class="show-more" @click="shownMonth++" v-if="shownMonth < Object.keys(myActions).length">Show more activity</button>
  </div>
</template>

<script>
const axios = require('axios')
const dayjs = require('dayjs')

export default {
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.name !== 'profile') return
        this.getActions()
      },
      { immediate: true }
    )
  },
  data() {
    return {
      renderKey: 1,
      myActions: [],
      shownMonth: 1,
    }
  },
  methods: {
    async getActions() {
      let response = await axios.get('https://ecoplotapi.besec.co.jp/api/actions/', { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
      let monthly = response.data.map((action) => ({ ...action, month: dayjs(action.time).format('MMMM YYYY') }))
      let groupByMonth = monthly.reduce(function (rv, x) {
        ;(rv[x.month] = rv[x.month] || []).push(x)
        return rv
      }, {})
      let groupByType = {}
      for (const key in groupByMonth) {
        groupByType[key] = groupByMonth[key].reduce(function (rv, x) {
          ;(rv[x.type] = rv[x.type] || []).push(x)
          return rv
        }, {})
      }
      for (const month in groupByType) {
        for (const type in groupByType[month]) {
          let isShow = groupByType[month][type].length > 5 ? false : true
          groupByType[month][type].show = isShow
          groupByType[month][type].message = this.generateMessage(type, groupByType[month][type])
        }
      }
      this.myActions = groupByType
    },
    toggleShow(month, type) {
      let old = this.myActions[month][type].show
      this.myActions[month][type].show = !old
      this.renderKey++
    },
    generateMessage(type, array) {
      let projects = [...new Set(array.map((p) => p.project_id))]
      switch (type) {
        case 'create':
          return `Created ${projects.length} project${projects.length === 1 ? '' : 's'}`

        case 'update':
          return `Made ${array.length} update on ${projects.length} project${projects.length === 1 ? '' : 's'}`

        case 'delete':
          return `Deleted ${projects.length} project${projects.length === 1 ? '' : 's'}`

        case 'share':
          return `Made ${array.length} share list change on ${projects.length} project${projects.length === 1 ? '' : 's'}`

        default:
          return ''
      }
    },
  },
}
</script>

<style scoped>
.dark-layout .no-action {
  text-align: center;
  color: #bbb;
}

.dark-layout .action-time {
  position: relative;
}

.dark-layout .action-time span {
  position: relative;
  z-index: 1;
  background-color: #283046;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #d0d2d6;
}

.dark-layout .action-time::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid #ddd;
  width: 100%;
  top: 50%;
  left: 0;
}

/* .action-time {
  font-size: 15px;
  color: #bbb;
} */

.dark-layout .action-content {
  position: relative;
  padding: 20px 0 20px 30px;
  border-left: 2px solid #ddd;
  margin-left: 15px;
}

.dark-layout .action-content .action-type {
  position: absolute;
  width: 32px;
  height: 32px;
  background: #ddd;
  border-radius: 50%;
  left: -17px;
  top: 20px;
}

.dark-layout .action-content .action-type img {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
  object-fit: cover;
}

.dark-layout .action-content .action-stats {
  font-size: 16px;
  color: #d0d2d6;
  line-height: 32px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dark-layout .action-content .action-stats i {
  font-size: 14px;
}

.dark-layout .action-content .action-message {
  font-size: 14px;
  color: #0366d6;
  line-height: 25px;
}

.dark-layout .show-more {
  font-weight: 600;
  color: #0366d6;
  background: #fff;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  font-size: 12px;
  height: 38px;
  margin-top: 20px;
}

.dark-layout .show-more:focus {
  outline: none;
}

.no-action {
  text-align: center;
  color: #bbb;
}

.action-time {
  position: relative;
}

.action-time span {
  position: relative;
  z-index: 1;
  background-color: #fff;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #24292e;
}

.action-time::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid #ddd;
  width: 100%;
  top: 50%;
  left: 0;
}

/* .action-time {
  font-size: 15px;
  color: #bbb;
} */

.action-content {
  position: relative;
  padding: 20px 0 20px 30px;
  border-left: 2px solid #ddd;
  margin-left: 15px;
}

.action-content .action-type {
  position: absolute;
  width: 32px;
  height: 32px;
  background: #ddd;
  border-radius: 50%;
  left: -17px;
  top: 20px;
}

.action-content .action-type img {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
  object-fit: cover;
}

.action-content .action-stats {
  font-size: 16px;
  color: #24292e;
  line-height: 32px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-content .action-stats i {
  font-size: 14px;
}

.action-content .action-message {
  font-size: 14px;
  color: #0366d6;
  line-height: 25px;
}

.show-more {
  font-weight: 600;
  color: #0366d6;
  background: #fff;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  font-size: 12px;
  height: 38px;
  margin-top: 20px;
}

.show-more:focus {
  outline: none;
}
</style>
