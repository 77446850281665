<template>
  <div class="profile-card p-20">
    <h3 class="card-title">Projects</h3>
    <div class="no-action" v-if="myProjects.length === 0">You has no project yet.</div>
    <hr v-if="myProjects.length !== 0" />
    <div v-for="project in myProjects" :key="project.id">
      <div class="project-item">
        <div class="left">
          <a :href="`https://localhost:3000/#/?projectId=${project.id}`" target="_blank" class="project-name">{{ project.name }}</a>
          <div class="project-link">{{ `https://localhost:3000/#/?shareId=${project.link}` }}</div>
          <div class="person-list">
            <div class="person-item" v-for="person in project.shared_persons" :key="person.id">
              <span :title="person.person">{{ person.name }}</span>
              <i class="fas fa-glasses permission" title="Read" @click="updatePermission(project.id, 'write', person.id)" v-if="person.permission === 'read'"></i>
              <i class="fas fa-pen permission" title="Write" @click="updatePermission(project.id, 'read', person.id)" v-if="person.permission === 'write'"></i>
              <i class="fas fa-trash" title="Remove" @click="deleteUser(project.id, person.id)"></i>
            </div>
          </div>
        </div>
        <div class="right">
          <select :value="project.mode" @input="(e) => saveMode(project.id, e.target.value)">
            <option value="public">Public</option>
            <option value="private">Private</option>
          </select>
          <select :value="project.permission" @input="(e) => savePermission(project.id, e.target.value)" v-if="project.mode === 'public'">
            <option value="read">Read</option>
            <option value="write">Write</option>
          </select>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.name !== 'profile') return
           this.getProjects()
      },
      { immediate: true }
    )
  },
  data() {
    return {
      myProjects: [],
    }
  },
  methods: {
    async getProjects() {
      let response = await axios.get('https://ecoplotapi.besec.co.jp/api/projects/', { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
      this.myProjects = response.data
    },
    async saveMode(id, mode) {
      let formdata = new FormData()
      formdata.append('mode', mode)

      try {
        await axios.put(`https://ecoplotapi.besec.co.jp/api/projects/${id}/`, formdata, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        let project = this.myProjects.find((p) => p.id === id)
        project.mode = mode
      } catch (error) {
        this.$notifyError(error)
      }
    },
    async savePermission(id, permission) {
      let formdata = new FormData()
      formdata.append('permission', permission)

      try {
        await this.$axios.put(`https://ecoplotapi.besec.co.jp/api/projects/${id}/`, formdata, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        let project = this.myProjects.find((p) => p.id === id)
        project.permission = permission
      } catch (error) {
        this.$notifyError(error)
      }
    },
    async updatePermission(projectId, permission, personId) {
      try {
        await this.$axios.put(`https://ecoplotapi.besec.co.jp/api/projects/${projectId}/shared-persons/${personId}/`, { permission }, { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        let project = this.myProjects.find((p) => p.id === projectId)
        let person = project.shared_persons.find((p) => p.id === personId)
        person.permission = permission
      } catch (error) {
        this.$notifyError(error)
      }
    },
    async deleteUser(projectId, personId) {
      try {
        await this.$axios.delete(`https://ecoplotapi.besec.co.jp/api/projects/${projectId}/shared-persons/${personId}/`, { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        let project = this.myProjects.find((p) => p.id === projectId)
        project.shared_persons = project.shared_persons.filter((s) => s.id !== personId)
      } catch (error) {
        this.$notifyError(error)
      }
    },
  },
}
</script>

<style scoped>
.no-action {
  text-align: center;
  color: #bbb;
}

hr {
  margin: 0;
}

.project-item {
  display: flex;
  padding: 30px 0;
  justify-content: space-between;
}

.project-item .left {
  flex-grow: 1;
}

.project-name {
  font-size: 20px;
  font-weight: 600;
  color: #0366d6;
}

.project-link {
  font-size: 14px;
  color: #586069;
  margin-bottom: 10px;
}
.dark-layout .project-link {
  font-size: 14px;
  color: #d0d2d6;
  margin-bottom: 10px;
}

select {
  display: block;
  height: 30px;
  width: 100px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 12px;
  color: #4a4a4a;
}
.dark-layout select {
  display: block;
  height: 30px;
  width: 100px;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  font-size: 12px;
  color: #d0d2d6;
  background: #283046;
}

select:hover {
  border-color: #b5b5b5;
}

select:first-child {
  margin-bottom: 6px;
}

.person-list {
  font-size: 12px;
  color: #586069;
  line-height: 20px;
}

.person-item {
  display: inline-block;
  width: auto;
  padding: 2px 5px;
  background: #fafbfc;
  border-radius: 4px;
  margin-right: 5px;
  border: 1px solid #ccc;
}

.dark-layout .person-item {
  display: inline-block;
  width: auto;
  padding: 2px 5px;
  background: #283046;
  border-radius: 4px;
  margin-right: 5px;
  border: 1px solid #b5b5b5;
  color: #d0d2d6;
}


.person-item:hover {
  background: #f3f4f6;
}

.dark-layout .person-item:hover {
  background: #24292e;
}

.person-item:last-child {
  margin-right: 0;
}

.project-item i {
  cursor: pointer;
}

.project-item i.permission {
  margin: 0 10px;
}
</style>
