<template>
  <div id="user-profile">
    <b-card class="profile-header mb-2 box-image-profile" :img-src="user.cover" img-top alt="cover photo" body-class="p-0">
      <!-- profile picture -->
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img" style="height: 100%; width: 100%">
            <!-- <b-img :src="user.avatar" rounded fluid alt="profile photo" /> -->
            <div class="avatar-wrapper m-0 p-0" @click="changeAvatar">
              <img :src="user ? user.avatar : ''" alt="avatar" />
              <div class="avatar-overlay" style="height: 120px; width: 120px">
                <img style="padding-bottom: 5px; width: 20px; height: 20px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAABqElEQVQ4jc3TT4hOYRQG8N8Z38w0zUwpZTULEhJJ2Y+RxIaU+JIwRYk9pSwUO2spUfJnFmpYWCCbSWqsZqNJ/sVCFt9GYTKYOhb3TdPX/TSakqdu7+0553nu8773PfzviD8VM3MHNrXRUxHxuJOmMU+8F7sxi2l8xAbcaNOMZuZgRNzNzCGcxO2ImIbIzKU4ixG8whdsxVMcjYhsS92Fa/iJ1ehHFyZwITLzID4XswZeYD1m0MLKssJyvCtrf9nJOsxhDQYb6EZfSThQBHvwFi9xFR+K4RCOYS1WlZ6d+IpP6InMHMX7iJho29pmnMN1LCt0C01cjIiptv4RrOhSg8wcxHncK+f5vDzb8QhnMnOgTtuoIzFakm2LiOPz+MnMvII7OIJL7cLahKrr0o3xmto4erCxTtjJsKH6c701td5SW/I3hs+KoJmZv6epvO8vH5zslKQOtzCGmxjLzPuF36U6v8M4sOCEEfENp3EID/AdP/CwmJ2KiNkFGxbT16o7N4ctGC6mzYh401GXmftUY/SkU9MCMYyZyMw+nFDN52LQwuVFevwD/AIvg4Qw8iH0GwAAAABJRU5ErkJggg==" />
              </div>
            </div>
          </div>
          <!-- profile title -->
          <div class="profile-title ml-3">
            <h2 class="text-white">
              {{ user.full_name }}
            </h2>
            <p class="text-white">
              {{ user.username }}
            </p>
          </div>
        </div>
      </div>
      <div class="profile-header-nav">
        <b-navbar toggleable="md" type="light">
          <b-navbar-toggle class="ml-auto" target="nav-text-collapse">
            <feather-icon icon="AlignJustifyIcon" size="21" />
          </b-navbar-toggle>

          <b-collapse id="nav-text-collapse" is-nav>
            <b-tabs pills class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
              <template #tabs-start>
                <b-nav-item role="presentation" :active="tab === 'Activities'" class="font-weight-bold">
                  <!-- <span class="d-none d-md-block">Activities</span> -->
                  <span class="tab-button" @click.prevent="tab = 'Activities'">Activities</span>
                  <feather-icon icon="RssIcon" class="d-block d-md-none" />
                </b-nav-item>
                <b-nav-item role="presentation" :active="tab === 'Links'" class="font-weight-bold">
                  <!-- <span class="d-none d-md-block">Projects</span> -->
                  <span class="tab-button" @click.prevent="tab = 'Links'">Projects</span>
                  <feather-icon icon="InfoIcon" class="d-block d-md-none" />
                </b-nav-item>
              </template>
            </b-tabs>
          </b-collapse>
        </b-navbar>
      </div>
      <button type="button" class="update-cover pt-10 pb-10" @click="changeCoverImage">
        <img style="padding-bottom: 5px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAABqElEQVQ4jc3TT4hOYRQG8N8Z38w0zUwpZTULEhJJ2Y+RxIaU+JIwRYk9pSwUO2spUfJnFmpYWCCbSWqsZqNJ/sVCFt9GYTKYOhb3TdPX/TSakqdu7+0553nu8773PfzviD8VM3MHNrXRUxHxuJOmMU+8F7sxi2l8xAbcaNOMZuZgRNzNzCGcxO2ImIbIzKU4ixG8whdsxVMcjYhsS92Fa/iJ1ehHFyZwITLzID4XswZeYD1m0MLKssJyvCtrf9nJOsxhDQYb6EZfSThQBHvwFi9xFR+K4RCOYS1WlZ6d+IpP6InMHMX7iJho29pmnMN1LCt0C01cjIiptv4RrOhSg8wcxHncK+f5vDzb8QhnMnOgTtuoIzFakm2LiOPz+MnMvII7OIJL7cLahKrr0o3xmto4erCxTtjJsKH6c701td5SW/I3hs+KoJmZv6epvO8vH5zslKQOtzCGmxjLzPuF36U6v8M4sOCEEfENp3EID/AdP/CwmJ2KiNkFGxbT16o7N4ctGC6mzYh401GXmftUY/SkU9MCMYyZyMw+nFDN52LQwuVFevwD/AIvg4Qw8iH0GwAAAABJRU5ErkJggg==" />

        Update cover picture
      </button>
    </b-card>

    <section id="profile-info">
      <b-row>
        <b-col lg="4" cols="12" order="2" order-lg="1">
          <b-card>
            <div class="mt-2">
              <h5 class="text-capitalize mb-75">About</h5>
              <b-card-text> Make sure all your information is correct. You can update it by typing directly on the input below. </b-card-text>
            </div>

            <div class="mt-2">
              <h5 class="text-capitalize mb-75">Your Email</h5>
              <b-card-text>
                <input type="text" id="email" :value="user ? user.username : ''" disabled="disabled" class="form-control" />
              </b-card-text>
            </div>

            <div class="mt-2">
              <h5 class="text-capitalize mb-75">Your Full Name</h5>
              <b-card-text>
                <input type="text" id="name" :value="user ? user.full_name : ''" @change="updateProfile" class="form-control" />
              </b-card-text>
            </div>

            <!-- <div class="mt-2 d-flex">
              <img src="../../../../static/images/social/google.png" alt="google" class="social-logo" />
              <h5 class="text-capitalize m-0 title-link">Google</h5>
              <img width="15px" height="15px" class="social-check" v-if="user ? user.google_id : false" src="../../../../static/images/social/check.png" alt="facebook" />
              <a href="#" class="link-page-google mr-0" v-if="user ? user.google_id : false" @click.prevent="unlinkGoogle">Unlink</a>
              <a href="#" class="link-page-google mr-0" v-else @click.prevent="linkGoogle">Link</a>
            </div> -->

            <!-- <div class="mt-2 d-flex">
              <img src="../../../../static/images/social/facebook.png" alt="google" class="social-logo" />
              <h5 class="text-capitalize m-0 title-link">Facebook</h5>
              <img width="15px" height="15px" class="social-check" v-if="user ? user.facebook_id : false" src="../../../../static/images/social/check.png" alt="facebook" />
              <a href="#" class="link-page-google mr-0" v-if="user ? user.facebook_id : false" @click.prevent="unlinkFacebook">Unlink</a>
              <a href="#" class="link-page-google mr-0" v-else @click.prevent="linkFacebook">Link</a>
            </div> -->
          </b-card>
        </b-col>
        <b-col lg="8" cols="12" order="1" order-lg="2">
          <b-card>
            <keep-alive>
              <component :is="tab"></component>
            </keep-alive>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <my-upload style="display: none" field="img" langType="en" v-model="showAvatar" :width="120" :height="120" :noCircle="noCircle" :noRotate="noRotate" img-format="png" @crop-success="cropAvatarSuccess"></my-upload>
    <my-upload field="img" langType="en" v-model="showBanner" :width="550" :height="200" :noCircle="noCircle" :noRotate="noRotate" img-format="png" @crop-success="cropBannerSuccess"></my-upload>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BRow, BCol, BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton, BCardText } from 'bootstrap-vue'
import Activities from './Activities'
import Links from './Links'
import myUpload from 'vue-image-crop-upload'

export default {
  components: { BRow, BCol, BCard, BTabs, BButton, BNavItem, BNavbar, BNavbarToggle, BCollapse, BImg, BCardText, Activities, Links, myUpload },
  //   mounted() {
  //   this.$store.dispatch('ecoplot/GET_USER').then(() => {
  //     if (!this.$store.state.ecoplot.user) this.$router.push('/login/')
  //     setTimeout(() => {
  //       if (this.$refs.header) this.$refs.header.jQueryJob()
  //     }, 1000)
  //   })
  // },
  data() {
    return {
      tab: 'Activities',
      showAvatar: false,
      showBanner: false,
      noCircle: true,
      noRotate: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.ecoplot.user
    },
  },
  methods: {
    linkGoogle() {
      window.auth2
        .grantOfflineAccess()
        .then((authResult) => {
          if (authResult.code) {
            this.$store.dispatch('ecoplot/GOOGLE_LINK', { code: authResult.code })
          }
        })
        .catch(() => {})
    },
    unlinkGoogle() {
      this.$store.dispatch('ecoplot/GOOGLE_UNLINK')
    },
    linkFacebook() {
      FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          this.$store.dispatch('ecoplot/FACEBOOK_LINK', { code: response.authResponse.accessToken })
        } else {
          window.FB.login(
            (response) => {
              if (response.status === 'connected') {
                this.$store.dispatch('ecoplot/FACEBOOK_LINK', { code: response.authResponse.accessToken })
              }
            },
            { scope: 'public_profile,email' }
          )
        }
      })
    },
    unlinkFacebook() {
      this.$store.dispatch('ecoplot/FACEBOOK_UNLINK')
    },
    cropAvatarSuccess(imgDataUrl, field) {
      this.$store.dispatch('ecoplot/CHANGE_AVATAR', { avatar: imgDataUrl.replace('data:image/png;base64,', '') })
    },
    cropBannerSuccess(imgDataUrl, field) {
      this.$store.dispatch('ecoplot/CHANGE_COVER', { cover: imgDataUrl.replace('data:image/png;base64,', '') })
    },
    changeAvatar() {
      this.showAvatar = true
    },
    changeCoverImage() {
      this.showBanner = true
    },
    updateProfile(e) {
      this.$store.dispatch('ecoplot/UPDATE_USER', { full_name: e.target.value })
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/pages/page-profile.scss';
.title-link {
  align-self: center;
}
.content-wrapper {
  overflow-x: hidden;
  /* overflow: auto; */
  height: 100%;
  overflow-y: auto;
}
.social-logo {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 5px;
}
.link-page-google {
  margin: auto;
  cursor: pointer;
  color: #007bff;
}
.box-image-profile {
  height: 450px !important;
}
.box-image-profile .card-img-top {
  height: 390px !important;
}

.profile-content {
  min-height: calc(100vh - 82px);
  position: relative;
  background: #f0f5fc;
}

.top-section {
  margin: 30px 0;
}

.bottom-section {
  margin-bottom: 30px;
}

.cover-picture {
  width: 100%;
  height: 400px;
  object-fit: cover;
  user-select: none;
}

.update-cover {
  position: absolute;
  width: auto;
  top: 330px;
  right: 10px;
  border: none;
  border-radius: 5px;
  background: #ffffff1a;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 0 10px;
  height: 50px;
}

.update-cover:hover {
  background: #fff3;
}

.update-cover:focus {
  outline: none;
}

.update-cover i {
  margin-right: 10px;
}

.tab-header {
  padding: 10px 0;
  position: relative;
  z-index: 1;
}

.tab-header .tab-button {
  font-size: 15px;
  font-weight: 600;
  padding: 5px 15px;
  line-height: 30px;
  border-radius: 5px;
  color: #504c89;
}

.tab-header .tab-button:first-child {
  margin-left: 230px;
}

.tab-header .tab-button.active {
  background: #1153fc;
  color: #fff;
}

.profile-overview {
  display: flex;
  align-items: center;
  position: absolute;
  top: 300px;
}

.profile-overview h3,
.profile-overview p {
  color: #fff;
}

.avatar-wrapper {
  border-radius: 5px;
  padding: 5px;
  box-shadow: 1.21px 4.851px 19.2px 0.8px rgb(60 57 213 / 10%);
  margin: 0 50px;
  background: #fff;
  position: relative;
  cursor: pointer;
}

.avatar-wrapper img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
  user-select: none;
}

.avatar-overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 120px;
  display: none;
  border-radius: 5px;
}

.avatar-wrapper:hover .avatar-overlay {
  display: block;
}

.profile-form .form-group input[type='submit'] {
  background: linear-gradient(-65deg, #c165dd 0%, #1153fc 100%);
  width: auto;
  padding: 0 50px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 auto;
  display: block;
  border: none;
  border-radius: 30px;
  box-shadow: 0 10px 30px rgb(118 93 234 / 50%);
  height: 60px;
}

.profile-form .form-group input {
  border: 1px solid transparent;
}

.social-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.social-item:last-child {
  margin-bottom: 0;
}

.social-item .social-logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 5px;
}

.social-check {
  width: 15px;
  height: 15px;
  object-fit: cover;
  margin-left: 5px;
  align-self: center;
}

.social-item a {
  margin-left: auto;
  font-weight: normal;
}

input[type='file'] {
  display: none;
}

.vue-image-crop-upload .vicp-wrap {
  width: 450px;
}

.vue-image-crop-upload .vicp-wrap .vicp-operate {
  bottom: 10px;
}

.vue-image-crop-upload .vicp-wrap .vicp-operate a {
  width: 50px;
}

a.change-password {
  color: #0403c4;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
}
</style>
